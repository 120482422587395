@font-face {
    font-family: Eva-Icons;
    src: url("./fonts/Eva-Icons.eot");
    src: url("./fonts/Eva-Icons.eot?#iefix") format("embedded-opentype"), url("./fonts/Eva-Icons.woff2") format("woff2"), url("./fonts/Eva-Icons.woff") format("woff"), url("./fonts/Eva-Icons.ttf") format("truetype"), url("./fonts/Eva-Icons.svg#Eva-Icons") format("svg");
    font-style: normal;
    font-weight: 400;
}

.eva {
    display: inline-block;
    transform: translate(0, 0);
    text-rendering: auto;
    font: normal normal 400 14px/1 Eva-Icons;
    font-size: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.eva-lg {
    vertical-align: -15%;
    line-height: 0.75em;
    font-size: 1.33333333em;
}

.eva-2x {
    font-size: 2em;
}

.eva-3x {
    font-size: 3em;
}

.eva-4x {
    font-size: 4em;
}

.eva-5x {
    font-size: 5em;
}

.eva-fw {
    width: 1.28571429em;
    text-align: center;
}

.eva-activity::before {
    content: "\ea01";
}

.eva-activity-outline::before {
    content: "\ea02";
}

.eva-alert-circle::before {
    content: "\ea03";
}

.eva-alert-circle-outline::before {
    content: "\ea04";
}

.eva-alert-triangle::before {
    content: "\ea05";
}

.eva-alert-triangle-outline::before {
    content: "\ea06";
}

.eva-archive::before {
    content: "\ea07";
}

.eva-archive-outline::before {
    content: "\ea08";
}

.eva-arrow-back::before {
    content: "\ea09";
}

.eva-arrow-back-outline::before {
    content: "\ea0a";
}

.eva-arrow-circle-down::before {
    content: "\ea0b";
}

.eva-arrow-circle-down-outline::before {
    content: "\ea0c";
}

.eva-arrow-circle-left::before {
    content: "\ea0d";
}

.eva-arrow-circle-left-outline::before {
    content: "\ea0e";
}

.eva-arrow-circle-right::before {
    content: "\ea0f";
}

.eva-arrow-circle-right-outline::before {
    content: "\ea10";
}

.eva-arrow-circle-up::before {
    content: "\ea11";
}

.eva-arrow-circle-up-outline::before {
    content: "\ea12";
}

.eva-arrow-down::before {
    content: "\ea13";
}

.eva-arrow-down-outline::before {
    content: "\ea14";
}

.eva-arrow-downward::before {
    content: "\ea15";
}

.eva-arrow-downward-outline::before {
    content: "\ea16";
}

.eva-arrow-forward::before {
    content: "\ea17";
}

.eva-arrow-forward-outline::before {
    content: "\ea18";
}

.eva-arrow-ios-back::before {
    content: "\ea19";
}

.eva-arrow-ios-back-outline::before {
    content: "\ea1a";
}

.eva-arrow-ios-downward::before {
    content: "\ea1b";
}

.eva-arrow-ios-downward-outline::before {
    content: "\ea1c";
}

.eva-arrow-ios-forward::before {
    content: "\ea1d";
}

.eva-arrow-ios-forward-outline::before {
    content: "\ea1e";
}

.eva-arrow-ios-upward::before {
    content: "\ea1f";
}

.eva-arrow-ios-upward-outline::before {
    content: "\ea20";
}

.eva-arrow-left::before {
    content: "\ea21";
}

.eva-arrow-left-outline::before {
    content: "\ea22";
}

.eva-arrow-right::before {
    content: "\ea23";
}

.eva-arrow-right-outline::before {
    content: "\ea24";
}

.eva-arrow-up::before {
    content: "\ea25";
}

.eva-arrow-up-outline::before {
    content: "\ea26";
}

.eva-arrow-upward::before {
    content: "\ea27";
}

.eva-arrow-upward-outline::before {
    content: "\ea28";
}

.eva-arrowhead-down::before {
    content: "\ea29";
}

.eva-arrowhead-down-outline::before {
    content: "\ea2a";
}

.eva-arrowhead-left::before {
    content: "\ea2b";
}

.eva-arrowhead-left-outline::before {
    content: "\ea2c";
}

.eva-arrowhead-right::before {
    content: "\ea2d";
}

.eva-arrowhead-right-outline::before {
    content: "\ea2e";
}

.eva-arrowhead-up::before {
    content: "\ea2f";
}

.eva-arrowhead-up-outline::before {
    content: "\ea30";
}

.eva-at::before {
    content: "\ea31";
}

.eva-at-outline::before {
    content: "\ea32";
}

.eva-attach::before {
    content: "\ea33";
}

.eva-attach-2::before {
    content: "\ea34";
}

.eva-attach-2-outline::before {
    content: "\ea35";
}

.eva-attach-outline::before {
    content: "\ea36";
}

.eva-award::before {
    content: "\ea37";
}

.eva-award-outline::before {
    content: "\ea38";
}

.eva-backspace::before {
    content: "\ea39";
}

.eva-backspace-outline::before {
    content: "\ea3a";
}

.eva-bar-chart::before {
    content: "\ea3b";
}

.eva-bar-chart-2::before {
    content: "\ea3c";
}

.eva-bar-chart-2-outline::before {
    content: "\ea3d";
}

.eva-bar-chart-outline::before {
    content: "\ea3e";
}

.eva-battery::before {
    content: "\ea3f";
}

.eva-battery-outline::before {
    content: "\ea40";
}

.eva-behance::before {
    content: "\ea41";
}

.eva-behance-outline::before {
    content: "\ea42";
}

.eva-bell::before {
    content: "\ea43";
}

.eva-bell-off::before {
    content: "\ea44";
}

.eva-bell-off-outline::before {
    content: "\ea45";
}

.eva-bell-outline::before {
    content: "\ea46";
}

.eva-bluetooth::before {
    content: "\ea47";
}

.eva-bluetooth-outline::before {
    content: "\ea48";
}

.eva-book::before {
    content: "\ea49";
}

.eva-book-open::before {
    content: "\ea4a";
}

.eva-book-open-outline::before {
    content: "\ea4b";
}

.eva-book-outline::before {
    content: "\ea4c";
}

.eva-bookmark::before {
    content: "\ea4d";
}

.eva-bookmark-outline::before {
    content: "\ea4e";
}

.eva-briefcase::before {
    content: "\ea4f";
}

.eva-briefcase-outline::before {
    content: "\ea50";
}

.eva-browser::before {
    content: "\ea51";
}

.eva-browser-outline::before {
    content: "\ea52";
}

.eva-brush::before {
    content: "\ea53";
}

.eva-brush-outline::before {
    content: "\ea54";
}

.eva-bulb::before {
    content: "\ea55";
}

.eva-bulb-outline::before {
    content: "\ea56";
}

.eva-calendar::before {
    content: "\ea57";
}

.eva-calendar-outline::before {
    content: "\ea58";
}

.eva-camera::before {
    content: "\ea59";
}

.eva-camera-outline::before {
    content: "\ea5a";
}

.eva-car::before {
    content: "\ea5b";
}

.eva-car-outline::before {
    content: "\ea5c";
}

.eva-cast::before {
    content: "\ea5d";
}

.eva-cast-outline::before {
    content: "\ea5e";
}

.eva-charging::before {
    content: "\ea5f";
}

.eva-charging-outline::before {
    content: "\ea60";
}

.eva-checkmark::before {
    content: "\ea61";
}

.eva-checkmark-circle::before {
    content: "\ea62";
}

.eva-checkmark-circle-2::before {
    content: "\ea63";
}

.eva-checkmark-circle-2-outline::before {
    content: "\ea64";
}

.eva-checkmark-circle-outline::before {
    content: "\ea65";
}

.eva-checkmark-outline::before {
    content: "\ea66";
}

.eva-checkmark-square::before {
    content: "\ea67";
}

.eva-checkmark-square-2::before {
    content: "\ea68";
}

.eva-checkmark-square-2-outline::before {
    content: "\ea69";
}

.eva-checkmark-square-outline::before {
    content: "\ea6a";
}

.eva-chevron-down::before {
    content: "\ea6b";
}

.eva-chevron-down-outline::before {
    content: "\ea6c";
}

.eva-chevron-left::before {
    content: "\ea6d";
}

.eva-chevron-left-outline::before {
    content: "\ea6e";
}

.eva-chevron-right::before {
    content: "\ea6f";
}

.eva-chevron-right-outline::before {
    content: "\ea70";
}

.eva-chevron-up::before {
    content: "\ea71";
}

.eva-chevron-up-outline::before {
    content: "\ea72";
}

.eva-clipboard::before {
    content: "\ea73";
}

.eva-clipboard-outline::before {
    content: "\ea74";
}

.eva-clock::before {
    content: "\ea75";
}

.eva-clock-outline::before {
    content: "\ea76";
}

.eva-close::before {
    content: "\ea77";
}

.eva-close-circle::before {
    content: "\ea78";
}

.eva-close-circle-outline::before {
    content: "\ea79";
}

.eva-close-outline::before {
    content: "\ea7a";
}

.eva-close-square::before {
    content: "\ea7b";
}

.eva-close-square-outline::before {
    content: "\ea7c";
}

.eva-cloud-download::before {
    content: "\ea7d";
}

.eva-cloud-download-outline::before {
    content: "\ea7e";
}

.eva-cloud-upload::before {
    content: "\ea7f";
}

.eva-cloud-upload-outline::before {
    content: "\ea80";
}

.eva-code::before {
    content: "\ea81";
}

.eva-code-download::before {
    content: "\ea82";
}

.eva-code-download-outline::before {
    content: "\ea83";
}

.eva-code-outline::before {
    content: "\ea84";
}

.eva-collapse::before {
    content: "\ea85";
}

.eva-collapse-outline::before {
    content: "\ea86";
}

.eva-color-palette::before {
    content: "\ea87";
}

.eva-color-palette-outline::before {
    content: "\ea88";
}

.eva-color-picker::before {
    content: "\ea89";
}

.eva-color-picker-outline::before {
    content: "\ea8a";
}

.eva-compass::before {
    content: "\ea8b";
}

.eva-compass-outline::before {
    content: "\ea8c";
}

.eva-copy::before {
    content: "\ea8d";
}

.eva-copy-outline::before {
    content: "\ea8e";
}

.eva-corner-down-left::before {
    content: "\ea8f";
}

.eva-corner-down-left-outline::before {
    content: "\ea90";
}

.eva-corner-down-right::before {
    content: "\ea91";
}

.eva-corner-down-right-outline::before {
    content: "\ea92";
}

.eva-corner-left-down::before {
    content: "\ea93";
}

.eva-corner-left-down-outline::before {
    content: "\ea94";
}

.eva-corner-left-up::before {
    content: "\ea95";
}

.eva-corner-left-up-outline::before {
    content: "\ea96";
}

.eva-corner-right-down::before {
    content: "\ea97";
}

.eva-corner-right-down-outline::before {
    content: "\ea98";
}

.eva-corner-right-up::before {
    content: "\ea99";
}

.eva-corner-right-up-outline::before {
    content: "\ea9a";
}

.eva-corner-up-left::before {
    content: "\ea9b";
}

.eva-corner-up-left-outline::before {
    content: "\ea9c";
}

.eva-corner-up-right::before {
    content: "\ea9d";
}

.eva-corner-up-right-outline::before {
    content: "\ea9e";
}

.eva-credit-card::before {
    content: "\ea9f";
}

.eva-credit-card-outline::before {
    content: "\eaa0";
}

.eva-crop::before {
    content: "\eaa1";
}

.eva-crop-outline::before {
    content: "\eaa2";
}

.eva-cube::before {
    content: "\eaa3";
}

.eva-cube-outline::before {
    content: "\eaa4";
}

.eva-diagonal-arrow-left-down::before {
    content: "\eaa5";
}

.eva-diagonal-arrow-left-down-outline::before {
    content: "\eaa6";
}

.eva-diagonal-arrow-left-up::before {
    content: "\eaa7";
}

.eva-diagonal-arrow-left-up-outline::before {
    content: "\eaa8";
}

.eva-diagonal-arrow-right-down::before {
    content: "\eaa9";
}

.eva-diagonal-arrow-right-down-outline::before {
    content: "\eaaa";
}

.eva-diagonal-arrow-right-up::before {
    content: "\eaab";
}

.eva-diagonal-arrow-right-up-outline::before {
    content: "\eaac";
}

.eva-done-all::before {
    content: "\eaad";
}

.eva-done-all-outline::before {
    content: "\eaae";
}

.eva-download::before {
    content: "\eaaf";
}

.eva-download-outline::before {
    content: "\eab0";
}

.eva-droplet::before {
    content: "\eab1";
}

.eva-droplet-off::before {
    content: "\eab2";
}

.eva-droplet-off-outline::before {
    content: "\eab3";
}

.eva-droplet-outline::before {
    content: "\eab4";
}

.eva-edit::before {
    content: "\eab5";
}

.eva-edit-2::before {
    content: "\eab6";
}

.eva-edit-2-outline::before {
    content: "\eab7";
}

.eva-edit-outline::before {
    content: "\eab8";
}

.eva-email::before {
    content: "\eab9";
}

.eva-email-outline::before {
    content: "\eaba";
}

.eva-expand::before {
    content: "\eabb";
}

.eva-expand-outline::before {
    content: "\eabc";
}

.eva-external-link::before {
    content: "\eabd";
}

.eva-external-link-outline::before {
    content: "\eabe";
}

.eva-eye::before {
    content: "\eabf";
}

.eva-eye-off::before {
    content: "\eac0";
}

.eva-eye-off-2::before {
    content: "\eac1";
}

.eva-eye-off-2-outline::before {
    content: "\eac2";
}

.eva-eye-off-outline::before {
    content: "\eac3";
}

.eva-eye-outline::before {
    content: "\eac4";
}

.eva-facebook::before {
    content: "\eac5";
}

.eva-facebook-outline::before {
    content: "\eac6";
}

.eva-file::before {
    content: "\eac7";
}

.eva-file-add::before {
    content: "\eac8";
}

.eva-file-add-outline::before {
    content: "\eac9";
}

.eva-file-outline::before {
    content: "\eaca";
}

.eva-file-remove::before {
    content: "\eacb";
}

.eva-file-remove-outline::before {
    content: "\eacc";
}

.eva-file-text::before {
    content: "\eacd";
}

.eva-file-text-outline::before {
    content: "\eace";
}

.eva-film::before {
    content: "\eacf";
}

.eva-film-outline::before {
    content: "\ead0";
}

.eva-flag::before {
    content: "\ead1";
}

.eva-flag-outline::before {
    content: "\ead2";
}

.eva-flash::before {
    content: "\ead3";
}

.eva-flash-off::before {
    content: "\ead4";
}

.eva-flash-off-outline::before {
    content: "\ead5";
}

.eva-flash-outline::before {
    content: "\ead6";
}

.eva-flip::before {
    content: "\ead7";
}

.eva-flip-2::before {
    content: "\ead8";
}

.eva-flip-2-outline::before {
    content: "\ead9";
}

.eva-flip-outline::before {
    content: "\eada";
}

.eva-folder::before {
    content: "\eadb";
}

.eva-folder-add::before {
    content: "\eadc";
}

.eva-folder-add-outline::before {
    content: "\eadd";
}

.eva-folder-outline::before {
    content: "\eade";
}

.eva-folder-remove::before {
    content: "\eadf";
}

.eva-folder-remove-outline::before {
    content: "\eae0";
}

.eva-funnel::before {
    content: "\eae1";
}

.eva-funnel-outline::before {
    content: "\eae2";
}

.eva-gift::before {
    content: "\eae3";
}

.eva-gift-outline::before {
    content: "\eae4";
}

.eva-github::before {
    content: "\eae5";
}

.eva-github-outline::before {
    content: "\eae6";
}

.eva-globe::before {
    content: "\eae7";
}

.eva-globe-2::before {
    content: "\eae8";
}

.eva-globe-2-outline::before {
    content: "\eae9";
}

.eva-globe-3::before {
    content: "\eaea";
}

.eva-globe-outline::before {
    content: "\eaeb";
}

.eva-google::before {
    content: "\eaec";
}

.eva-google-outline::before {
    content: "\eaed";
}

.eva-grid::before {
    content: "\eaee";
}

.eva-grid-outline::before {
    content: "\eaef";
}

.eva-hard-drive::before {
    content: "\eaf0";
}

.eva-hard-drive-outline::before {
    content: "\eaf1";
}

.eva-hash::before {
    content: "\eaf2";
}

.eva-hash-outline::before {
    content: "\eaf3";
}

.eva-headphones::before {
    content: "\eaf4";
}

.eva-headphones-outline::before {
    content: "\eaf5";
}

.eva-heart::before {
    content: "\eaf6";
}

.eva-heart-outline::before {
    content: "\eaf7";
}

.eva-home::before {
    content: "\eaf8";
}

.eva-home-outline::before {
    content: "\eaf9";
}

.eva-image::before {
    content: "\eafa";
}

.eva-image-2::before {
    content: "\eafb";
}

.eva-image-outline::before {
    content: "\eafc";
}

.eva-inbox::before {
    content: "\eafd";
}

.eva-inbox-outline::before {
    content: "\eafe";
}

.eva-info::before {
    content: "\eaff";
}

.eva-info-outline::before {
    content: "\eb00";
}

.eva-keypad::before {
    content: "\eb01";
}

.eva-keypad-outline::before {
    content: "\eb02";
}

.eva-layers::before {
    content: "\eb03";
}

.eva-layers-outline::before {
    content: "\eb04";
}

.eva-layout::before {
    content: "\eb05";
}

.eva-layout-outline::before {
    content: "\eb06";
}

.eva-link::before {
    content: "\eb07";
}

.eva-link-2::before {
    content: "\eb08";
}

.eva-link-2-outline::before {
    content: "\eb09";
}

.eva-link-outline::before {
    content: "\eb0a";
}

.eva-linkedin::before {
    content: "\eb0b";
}

.eva-linkedin-outline::before {
    content: "\eb0c";
}

.eva-list::before {
    content: "\eb0d";
}

.eva-list-outline::before {
    content: "\eb0e";
}

.eva-loader-outline::before {
    content: "\eb0f";
}

.eva-lock::before {
    content: "\eb10";
}

.eva-lock-outline::before {
    content: "\eb11";
}

.eva-log-in::before {
    content: "\eb12";
}

.eva-log-in-outline::before {
    content: "\eb13";
}

.eva-log-out::before {
    content: "\eb14";
}

.eva-log-out-outline::before {
    content: "\eb15";
}

.eva-map::before {
    content: "\eb16";
}

.eva-map-outline::before {
    content: "\eb17";
}

.eva-maximize::before {
    content: "\eb18";
}

.eva-maximize-outline::before {
    content: "\eb19";
}

.eva-menu::before {
    content: "\eb1a";
}

.eva-menu-2::before {
    content: "\eb1b";
}

.eva-menu-2-outline::before {
    content: "\eb1c";
}

.eva-menu-arrow::before {
    content: "\eb1d";
}

.eva-menu-arrow-outline::before {
    content: "\eb1e";
}

.eva-menu-outline::before {
    content: "\eb1f";
}

.eva-message-circle::before {
    content: "\eb20";
}

.eva-message-circle-outline::before {
    content: "\eb21";
}

.eva-message-square::before {
    content: "\eb22";
}

.eva-message-square-outline::before {
    content: "\eb23";
}

.eva-mic::before {
    content: "\eb24";
}

.eva-mic-off::before {
    content: "\eb25";
}

.eva-mic-off-outline::before {
    content: "\eb26";
}

.eva-mic-outline::before {
    content: "\eb27";
}

.eva-minimize::before {
    content: "\eb28";
}

.eva-minimize-outline::before {
    content: "\eb29";
}

.eva-minus::before {
    content: "\eb2a";
}

.eva-minus-circle::before {
    content: "\eb2b";
}

.eva-minus-circle-outline::before {
    content: "\eb2c";
}

.eva-minus-outline::before {
    content: "\eb2d";
}

.eva-minus-square::before {
    content: "\eb2e";
}

.eva-minus-square-outline::before {
    content: "\eb2f";
}

.eva-monitor::before {
    content: "\eb30";
}

.eva-monitor-outline::before {
    content: "\eb31";
}

.eva-moon::before {
    content: "\eb32";
}

.eva-moon-outline::before {
    content: "\eb33";
}

.eva-more-horizontal::before {
    content: "\eb34";
}

.eva-more-horizontal-outline::before {
    content: "\eb35";
}

.eva-more-vertical::before {
    content: "\eb36";
}

.eva-more-vertical-outline::before {
    content: "\eb37";
}

.eva-move::before {
    content: "\eb38";
}

.eva-move-outline::before {
    content: "\eb39";
}

.eva-music::before {
    content: "\eb3a";
}

.eva-music-outline::before {
    content: "\eb3b";
}

.eva-navigation::before {
    content: "\eb3c";
}

.eva-navigation-2::before {
    content: "\eb3d";
}

.eva-navigation-2-outline::before {
    content: "\eb3e";
}

.eva-navigation-outline::before {
    content: "\eb3f";
}

.eva-npm::before {
    content: "\eb40";
}

.eva-npm-outline::before {
    content: "\eb41";
}

.eva-options::before {
    content: "\eb42";
}

.eva-options-2::before {
    content: "\eb43";
}

.eva-options-2-outline::before {
    content: "\eb44";
}

.eva-options-outline::before {
    content: "\eb45";
}

.eva-pantone::before {
    content: "\eb46";
}

.eva-pantone-outline::before {
    content: "\eb47";
}

.eva-paper-plane::before {
    content: "\eb48";
}

.eva-paper-plane-outline::before {
    content: "\eb49";
}

.eva-pause-circle::before {
    content: "\eb4a";
}

.eva-pause-circle-outline::before {
    content: "\eb4b";
}

.eva-people::before {
    content: "\eb4c";
}

.eva-people-outline::before {
    content: "\eb4d";
}

.eva-percent::before {
    content: "\eb4e";
}

.eva-percent-outline::before {
    content: "\eb4f";
}

.eva-person::before {
    content: "\eb50";
}

.eva-person-add::before {
    content: "\eb51";
}

.eva-person-add-outline::before {
    content: "\eb52";
}

.eva-person-delete::before {
    content: "\eb53";
}

.eva-person-delete-outline::before {
    content: "\eb54";
}

.eva-person-done::before {
    content: "\eb55";
}

.eva-person-done-outline::before {
    content: "\eb56";
}

.eva-person-outline::before {
    content: "\eb57";
}

.eva-person-remove::before {
    content: "\eb58";
}

.eva-person-remove-outline::before {
    content: "\eb59";
}

.eva-phone::before {
    content: "\eb5a";
}

.eva-phone-call::before {
    content: "\eb5b";
}

.eva-phone-call-outline::before {
    content: "\eb5c";
}

.eva-phone-missed::before {
    content: "\eb5d";
}

.eva-phone-missed-outline::before {
    content: "\eb5e";
}

.eva-phone-off::before {
    content: "\eb5f";
}

.eva-phone-off-outline::before {
    content: "\eb60";
}

.eva-phone-outline::before {
    content: "\eb61";
}

.eva-pie-chart::before {
    content: "\eb62";
}

.eva-pie-chart-2::before {
    content: "\eb63";
}

.eva-pie-chart-outline::before {
    content: "\eb64";
}

.eva-pin::before {
    content: "\eb65";
}

.eva-pin-outline::before {
    content: "\eb66";
}

.eva-play-circle::before {
    content: "\eb67";
}

.eva-play-circle-outline::before {
    content: "\eb68";
}

.eva-plus::before {
    content: "\eb69";
}

.eva-plus-circle::before {
    content: "\eb6a";
}

.eva-plus-circle-outline::before {
    content: "\eb6b";
}

.eva-plus-outline::before {
    content: "\eb6c";
}

.eva-plus-square::before {
    content: "\eb6d";
}

.eva-plus-square-outline::before {
    content: "\eb6e";
}

.eva-power::before {
    content: "\eb6f";
}

.eva-power-outline::before {
    content: "\eb70";
}

.eva-pricetags::before {
    content: "\eb71";
}

.eva-pricetags-outline::before {
    content: "\eb72";
}

.eva-printer::before {
    content: "\eb73";
}

.eva-printer-outline::before {
    content: "\eb74";
}

.eva-question-mark::before {
    content: "\eb75";
}

.eva-question-mark-circle::before {
    content: "\eb76";
}

.eva-question-mark-circle-outline::before {
    content: "\eb77";
}

.eva-question-mark-outline::before {
    content: "\eb78";
}

.eva-radio::before {
    content: "\eb79";
}

.eva-radio-button-off::before {
    content: "\eb7a";
}

.eva-radio-button-off-outline::before {
    content: "\eb7b";
}

.eva-radio-button-on::before {
    content: "\eb7c";
}

.eva-radio-button-on-outline::before {
    content: "\eb7d";
}

.eva-radio-outline::before {
    content: "\eb7e";
}

.eva-recording::before {
    content: "\eb7f";
}

.eva-recording-outline::before {
    content: "\eb80";
}

.eva-refresh::before {
    content: "\eb81";
}

.eva-refresh-outline::before {
    content: "\eb82";
}

.eva-repeat::before {
    content: "\eb83";
}

.eva-repeat-outline::before {
    content: "\eb84";
}

.eva-rewind-left::before {
    content: "\eb85";
}

.eva-rewind-left-outline::before {
    content: "\eb86";
}

.eva-rewind-right::before {
    content: "\eb87";
}

.eva-rewind-right-outline::before {
    content: "\eb88";
}

.eva-save::before {
    content: "\eb89";
}

.eva-save-outline::before {
    content: "\eb8a";
}

.eva-scissors::before {
    content: "\eb8b";
}

.eva-scissors-outline::before {
    content: "\eb8c";
}

.eva-search::before {
    content: "\eb8d";
}

.eva-search-outline::before {
    content: "\eb8e";
}

.eva-settings::before {
    content: "\eb8f";
}

.eva-settings-2::before {
    content: "\eb90";
}

.eva-settings-2-outline::before {
    content: "\eb91";
}

.eva-settings-outline::before {
    content: "\eb92";
}

.eva-shake::before {
    content: "\eb93";
}

.eva-shake-outline::before {
    content: "\eb94";
}

.eva-share::before {
    content: "\eb95";
}

.eva-share-outline::before {
    content: "\eb96";
}

.eva-shield::before {
    content: "\eb97";
}

.eva-shield-off::before {
    content: "\eb98";
}

.eva-shield-off-outline::before {
    content: "\eb99";
}

.eva-shield-outline::before {
    content: "\eb9a";
}

.eva-shopping-bag::before {
    content: "\eb9b";
}

.eva-shopping-bag-outline::before {
    content: "\eb9c";
}

.eva-shopping-cart::before {
    content: "\eb9d";
}

.eva-shopping-cart-outline::before {
    content: "\eb9e";
}

.eva-shuffle::before {
    content: "\eb9f";
}

.eva-shuffle-2::before {
    content: "\eba0";
}

.eva-shuffle-2-outline::before {
    content: "\eba1";
}

.eva-shuffle-outline::before {
    content: "\eba2";
}

.eva-skip-back::before {
    content: "\eba3";
}

.eva-skip-back-outline::before {
    content: "\eba4";
}

.eva-skip-forward::before {
    content: "\eba5";
}

.eva-skip-forward-outline::before {
    content: "\eba6";
}

.eva-slash::before {
    content: "\eba7";
}

.eva-slash-outline::before {
    content: "\eba8";
}

.eva-smartphone::before {
    content: "\eba9";
}

.eva-smartphone-outline::before {
    content: "\ebaa";
}

.eva-speaker::before {
    content: "\ebab";
}

.eva-speaker-outline::before {
    content: "\ebac";
}

.eva-square::before {
    content: "\ebad";
}

.eva-square-outline::before {
    content: "\ebae";
}

.eva-star::before {
    content: "\ebaf";
}

.eva-star-outline::before {
    content: "\ebb0";
}

.eva-stop-circle::before {
    content: "\ebb1";
}

.eva-stop-circle-outline::before {
    content: "\ebb2";
}

.eva-sun::before {
    content: "\ebb3";
}

.eva-sun-outline::before {
    content: "\ebb4";
}

.eva-swap::before {
    content: "\ebb5";
}

.eva-swap-outline::before {
    content: "\ebb6";
}

.eva-sync::before {
    content: "\ebb7";
}

.eva-sync-outline::before {
    content: "\ebb8";
}

.eva-text::before {
    content: "\ebb9";
}

.eva-text-outline::before {
    content: "\ebba";
}

.eva-thermometer::before {
    content: "\ebbb";
}

.eva-thermometer-minus::before {
    content: "\ebbc";
}

.eva-thermometer-minus-outline::before {
    content: "\ebbd";
}

.eva-thermometer-outline::before {
    content: "\ebbe";
}

.eva-thermometer-plus::before {
    content: "\ebbf";
}

.eva-thermometer-plus-outline::before {
    content: "\ebc0";
}

.eva-toggle-left::before {
    content: "\ebc1";
}

.eva-toggle-left-outline::before {
    content: "\ebc2";
}

.eva-toggle-right::before {
    content: "\ebc3";
}

.eva-toggle-right-outline::before {
    content: "\ebc4";
}

.eva-trash::before {
    content: "\ebc5";
}

.eva-trash-2::before {
    content: "\ebc6";
}

.eva-trash-2-outline::before {
    content: "\ebc7";
}

.eva-trash-outline::before {
    content: "\ebc8";
}

.eva-trending-down::before {
    content: "\ebc9";
}

.eva-trending-down-outline::before {
    content: "\ebca";
}

.eva-trending-up::before {
    content: "\ebcb";
}

.eva-trending-up-outline::before {
    content: "\ebcc";
}

.eva-tv::before {
    content: "\ebcd";
}

.eva-tv-outline::before {
    content: "\ebce";
}

.eva-twitter::before {
    content: "\ebcf";
}

.eva-twitter-outline::before {
    content: "\ebd0";
}

.eva-umbrella::before {
    content: "\ebd1";
}

.eva-umbrella-outline::before {
    content: "\ebd2";
}

.eva-undo::before {
    content: "\ebd3";
}

.eva-undo-outline::before {
    content: "\ebd4";
}

.eva-unlock::before {
    content: "\ebd5";
}

.eva-unlock-outline::before {
    content: "\ebd6";
}

.eva-upload::before {
    content: "\ebd7";
}

.eva-upload-outline::before {
    content: "\ebd8";
}

.eva-video::before {
    content: "\ebd9";
}

.eva-video-off::before {
    content: "\ebda";
}

.eva-video-off-outline::before {
    content: "\ebdb";
}

.eva-video-outline::before {
    content: "\ebdc";
}

.eva-volume-down::before {
    content: "\ebdd";
}

.eva-volume-down-outline::before {
    content: "\ebde";
}

.eva-volume-mute::before {
    content: "\ebdf";
}

.eva-volume-mute-outline::before {
    content: "\ebe0";
}

.eva-volume-off::before {
    content: "\ebe1";
}

.eva-volume-off-outline::before {
    content: "\ebe2";
}

.eva-volume-up::before {
    content: "\ebe3";
}

.eva-volume-up-outline::before {
    content: "\ebe4";
}

.eva-wifi::before {
    content: "\ebe5";
}

.eva-wifi-off::before {
    content: "\ebe6";
}

.eva-wifi-off-outline::before {
    content: "\ebe7";
}

.eva-wifi-outline::before {
    content: "\ebe8";
}
